import {List} from "../models/OpenWeather";
import {useEffect, useState} from "react";
import {weatherCodeToIcon} from "../utils/weatherCodeToIcon";

interface IProps {
  forecast: List;
}

const images = require.context('../assets/icons/flat', true);

export default function ForecastItem(props: IProps) {
  const [icon, setIcon] = useState<string | undefined>(undefined);

  useEffect(() => {
    const image = images(`./${weatherCodeToIcon(props.forecast.weather[0].id)}.png`);
    setIcon(image);
  }, [props.forecast.weather]);

  return (
    <div className={"h-24 w-12 min-w-12 rounded-lg bg-white py-2 bg-opacity-5 flex flex-col justify-center items-center"}>
      <div className={"text-primary-100 text-sm"}>
        {props.forecast.dt_txt.split(" ")[1].split(":")[0]}h
      </div>
      <div className={"text-primary-100 text-sm flex-grow flex justify-center items-center"}>
        <img src={icon} className={"w-6 h-6"} alt="weather icon"/>
      </div>
      <div className={"text-primary-100 text-sm"}>
        {Math.round(props.forecast.main.temp || 0)}°
      </div>
    </div>
  )
}
