import circle_user from "../assets/icons/flat/circle_user.png";
import {useNavigate} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import UserController from "../controllers/UserController";
import cachedQueryClient from "../utils/cachedQueryClient";
import {urlBase64ToUint8Array} from "../utils/urlBase64ToUint8Array";

export default function PageProfile() {
  const navigate = useNavigate();

  const {isLoading, isError, data, error} = useQuery({
    queryKey: ['user'], queryFn: UserController.getProfile,
    gcTime: 1000 * 60 * 5,
    staleTime: 1000 * 60 * 5,
  }, cachedQueryClient)

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/auth/login");
  }

  const notifications = async () => {
    const permission = await Notification.requestPermission();
    console.log(permission);
    if (permission === "granted") {
      const registration = await navigator.serviceWorker.getRegistration()
      let subscription = await registration?.pushManager.getSubscription();
      if (!subscription) {
        subscription = await registration?.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array("BJSe3riHQ8GET8ND1kKqXQEcmRuQdK-PxnFfa-ekHbDuSYjbhIPTqgHDaXfTAaxRDQQOUFHK7LNkwjAw6qJRaVA")
        });
      }
      await UserController.postNotificationSubscription(subscription);
      window.location.reload();
    }
  }

  const sendNotification = async () => {
    await UserController.sendTestNotification();
  }

  return (
    <div className="flex flex-col justify-center mt-8">
      <div className={"flex justify-center items-center"}>
        <img src={circle_user} className={"h-20 w-20"} alt={"user avatar"}/>
      </div>
      <span className="text-white text-2xl text-center font-bold">{data?.user.name}</span>
      <span className="text-white opacity-70 text-l text-center">{data?.user.email}</span>
      {Notification.permission === "default" &&
        <button
          className="text-white bg-blue-500 bg-opacity-80 p-2 rounded-xl text-l text-center mt-5 hover:opacity-90 transition-all duration-200 ease-in-out"
          onClick={notifications}>
          Allow notifications
        </button>
      }
      {Notification.permission === "granted" &&
        <button
          className="text-white bg-blue-500 bg-opacity-80 p-2 rounded-xl text-l text-center mt-5 hover:opacity-90 transition-all duration-200 ease-in-out"
          onClick={sendNotification}>
          Send test notification
        </button>
      }
      <button
        className="text-white bg-red-500 bg-opacity-80 p-2 rounded-xl text-l text-center mt-5 hover:opacity-90 transition-all duration-200 ease-in-out"
        onClick={logout}>
        Logout
      </button>
    </div>
  );
}
