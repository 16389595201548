import {Weather} from "../models/OpenWeather";
import checkSavedLocationValidity from "../utils/checkLocationValidity";
import ApiController from "./apiController";


export interface WeatherData {
  city: string,
  weather: Weather,
  forecast: any
}

export interface LocationCoordinate {
  latitude: number,
  longitude: number
}

export default class WeatherController {

  static async getWeatherFromLocation(): Promise<WeatherData | null> {
    let coords = checkSavedLocationValidity();
    if (!coords) return null;

    const weather = await ApiController.fetchWeatherFromCoords(coords);
    if (weather === null) return null;

    const forecast = await ApiController.fetchForecastFromCoords(coords);
    if (forecast === null) return null;

    return {
      city: weather.city,
      weather: weather.weather,
      forecast: forecast.forecast
    }
  }

  static async getWeatherFromCity(city: string): Promise<WeatherData | null> {
    const weather = await ApiController.fetchWeatherFromCity(city);
    if (weather === null) return null;

    const forecast = await ApiController.fetchForecastFromCity(city);
    if (forecast === null) return null;

    return {
      city: weather.city,
      weather: weather.weather,
      forecast: forecast.forecast
    }
  }
}