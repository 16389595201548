import { Outlet } from "react-router-dom";
import "../css/scroll-bar.css";

export default function LayoutAuthRoot() {
  return (
    <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-0 md:pt-20">
      <div className="mx-auto max-w-md rounded-2xl">
        <Outlet />
      </div>
    </main>
  )
}
