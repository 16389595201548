import DetailedWeather from "../components/DetailedWeather";
import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import WeatherController from "../controllers/WeatherController";
import Card from "../components/Card";

export default function PageCity() {
  const {city} = useParams();

  const fetchWeatherFromCity = () => {
    if (city) {
      console.log("Fetching weather from city", city)
      return WeatherController.getWeatherFromCity(city);
    } else {
      throw new Error("City not found");
    }
  };

  const {isLoading, isError, data, error} = useQuery({
    queryKey: ['city', 'weather', 'forecast', city],
    queryFn: fetchWeatherFromCity,
    gcTime: 1000 * 60 * 5,
    enabled: !!city,
  })

  if (isLoading) {
    return (
      <Card className={"mt-5"}>
        <h1 className={"text-2xl text-gray-300 font-bold"}>Loading weather information...</h1>
      </Card>
    )
  }

  if (isError) {
    return (
      <Card className={"mt-5"}>
        <h1 className={"text-2xl text-gray-300 font-bold"}>{error.message}</h1>
      </Card>
    )
  }

  if (!city || !data) {
    return (
      <Card className={"mt-5"}>
        <h1 className={"text-2xl text-gray-300 font-bold"}>An error occurred while fetching the weather information</h1>
      </Card>
    )
  }

  return (
    <DetailedWeather city={data?.city} weather={data?.weather} forecast={data?.forecast}/>
  )
}
