import {useEffect, useState} from "react";
import {OpenWeather, OpenWeatherForecast} from "../models/OpenWeather";
import snowflake from "../assets/icons/3D/storm.png";
import Card from "./Card";
import clock from "../assets/icons/white/clock.png";
import Forecast from "./Forecast";
import temperature_high from "../assets/icons/white/temperature_high.png";
import temperature_low from "../assets/icons/white/temperature_low.png";
import gauge from "../assets/icons/white/gauge.png";
import droplet from "../assets/icons/white/droplet.png";
import wind from "../assets/icons/white/wind.png";
import {weatherCodeToIcon} from "../utils/weatherCodeToIcon";


const images = require.context('../assets/icons/3D', true);

interface IProps {
  city: string;
  weather?: OpenWeather;
  forecast?: OpenWeatherForecast;
}

export default function DetailedWeather({weather, forecast, city}: IProps) {
  const [icon, setIcon] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (weather) {
      console.log(weather)
      const image = images(`./${weatherCodeToIcon(weather.weather[0].id)}.png`);
      setIcon(image);
    } else {
      setIcon(snowflake);
    }
  }, [weather]);

  return (
    <div className={"mt-5"}>
      <img src={icon} className="h-32 mx-auto" alt="snowflake"/>
      <h2 className={'text-center text-primary-100 text-2xl font-bold mb-2'}>{city}</h2>
      <h3
        className={'text-center text-primary-100 text-6xl font-thin mb-6'}>{Math.round(weather?.main.temp || 0)}°C</h3>
      <div className={"grid grid-cols-1 gap-3"}>
        <Card img={clock} title={"Forecast"}>
          <Forecast forecast={forecast}/>
        </Card>
        <div className={'grid grid-cols-2 gap-3'}>
          <Card img={temperature_high} title={"Temperature max"}>
            <span className={"text-5xl"}>{Math.round(weather?.main.temp_max || 0)}</span>
            <span className={"text-2xl"}>°C</span>
          </Card>
          <Card img={temperature_low} title={"Temperature min"}>
            <span className={"text-5xl"}>{Math.round(weather?.main.temp_min || 0)}</span>
            <span className={"text-2xl"}>°C</span>
          </Card>
          <Card img={droplet} title={"Humidity"}>
            <span className={"text-5xl"}>{Math.round(weather?.main.humidity || 0)}</span>
            <span className={"text-2xl"}>%</span>
          </Card>
          <Card img={gauge} title={"Pressure"}>
            <span className={"text-5xl"}>
              {Math.round(weather?.main.pressure || 0)}
            </span>
            <span className={"text-2xl"}>hPa</span>
          </Card>
        </div>
        <Card img={wind} title={"Wind speed"}>
          <span className={"text-5xl"}>
              {Math.round(weather?.wind.speed || 0)}
            </span>
          <span className={"text-2xl"}>km/h</span>
        </Card>
      </div>
    </div>
  )
}
