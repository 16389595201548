import React from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './index.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import LayoutRoot from "./layout/Root";
import PageHome from "./pages/Home";
import PageErrorBoundary from "./pages/Errors";
import PageLogin from "./pages/Login";
import PageRegister from "./pages/Register";
import PageCity from "./pages/PageCity";
import PageProfile from "./pages/Profile";
import LayoutAuthRoot from "./layout/AuthRoot";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LayoutRoot />,
    errorElement: <PageErrorBoundary />,
    children: [
      {
        path: "/",
        element: <PageHome />
      },
      {
        path: "/city/:city",
        element: <PageCity />,
      },
      {
        path: "/me",
        element: <PageProfile />
      }
    ]
  },
  {
    path: "/auth",
    element: <LayoutAuthRoot />,
    errorElement: <PageErrorBoundary />,
    children: [
      {
        path: "login",
        element: <PageLogin />
      },
      {
        path: "register",
        element: <PageRegister />
      }
    ]
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: registration => {
    if (registration.waiting) {
      console.log('Service worker update available');
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  },
  onSuccess: _ => {
    console.log('Service worker registration successful');
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
