import {useQuery} from "@tanstack/react-query";
import WeatherController from "../controllers/WeatherController";
import DetailedWeather from "../components/DetailedWeather";
import Card from "../components/Card";
import {useNavigate} from "react-router-dom";
import {reloadLocation} from "../utils/checkLocationValidity";
import {useState} from "react";

export default function PageHome() {
  const navigate = useNavigate();
  const [reload, setReload] = useState<boolean>(false)

  const {isLoading, isError, data, error} = useQuery({
    queryKey: ['city', 'weather', 'forecast'], queryFn: WeatherController.getWeatherFromLocation,
    gcTime: 1000 * 60 * 5,
  })

  if (isLoading) {
    return (
      <Card className={"mt-5"}>
        <h1 className={"text-2xl text-gray-300 font-bold"}>Loading weather information...</h1>
      </Card>
    )
  }

  if (isError) {
    return (
      <Card className={"mt-5"}>
        <h1 className={"text-2xl text-gray-300 font-bold"}>{error.message}</h1>
      </Card>
    )
  }

  if (!data) {
    return (
      <Card className={"mt-5"}>
        <h1 className={"text-2xl text-red-700 font-bold"}>Location not found</h1>
        <p className={"text-gray-100"}>Please allow location access to get the weather information</p>
        <div className={"flex justify-center items-center"}>
          <button className={"bg-primary-500 text-primary-50 font-bold py-2 px-4 rounded-lg mt-4"}
                  onClick={() => {
                    reloadLocation();
                    setReload(true);
                    setTimeout(() => {
                      setReload(false)
                      navigate(0)
                    }, 2000);
                  }}>
            {reload ? "Reloading..." : "Refresh location coordinates"}
          </button>
        </div>
      </Card>
    )
  }

  return <DetailedWeather city={data.city} weather={data.weather} forecast={data.forecast}/>;
}
