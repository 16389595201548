import {ReactNode} from "react";
import classNames from "classnames";
import {Link} from "react-router-dom";

interface ButtonProps {
  children: ReactNode;
  icon?: boolean;
  className?: string;
  onClick?: () => void;
  to?: string;
}

export default function Button(props: ButtonProps) {
  return (
    <>
      {props.to ? (
        <Link to={props.to}
                className={classNames('flex mt-1 justify-center items-center rounded-full h-10 bg-gray-100 bg-opacity-10 backdrop-blur-md text-primary-100 hover:bg-white/20 transition-all duration-300', {
                  'w-10': props.icon,
                  'px-4': !props.icon
                }, props.className)}>
          {props.children}
        </Link>
      ) : (
        <button onClick={props.onClick}
                className={classNames('flex mt-1 justify-center items-center rounded-full h-10 bg-gray-100 bg-opacity-10 backdrop-blur-md text-primary-100 hover:bg-white/20 transition-all duration-300', {
                  'w-10': props.icon,
                  'px-4': !props.icon
                }, props.className)}>
          {props.children}
        </button>
      )}
    </>
  );
}