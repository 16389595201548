import ApiController from "./apiController";
import {User} from "../models/User";

export interface UserInfos {
  user: User;
}

export interface SavedLocations {
  locations: string[];
}

export default class UserController {

  static async getProfile(): Promise<UserInfos | null> {
    const user = await ApiController.getUserInfos();
    if (user === null) return null;

    const locations = await ApiController.listLocations();
    if (locations === null) return null;

    return {user};
  }

  static async getSavedLocations(): Promise<SavedLocations | null> {
    const locations = await ApiController.listLocations();
    if (locations === null) return null;

    return {locations};
  }

  static async addLocation(location: string): Promise<boolean> {
    return await ApiController.addLocation(location);
  }

  static async postNotificationSubscription(subscription: any): Promise<boolean> {
    return await ApiController.postNotificationSubscription(subscription);
  }

  static async sendTestNotification(): Promise<boolean> {
    return await ApiController.sendTestNotification();
  }

}
