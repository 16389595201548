import {LocationCoordinate} from "../controllers/WeatherController";

export default function checkSavedLocationValidity(): LocationCoordinate | false {
  let coordsString = localStorage.getItem('coords');

  if (!coordsString) return false;

  let coords: LocationCoordinate = JSON.parse(coordsString);

  if (!coords) return false;

  if (coords.latitude === 0 && coords.longitude === 0) return false;

  return coords;
}

export function reloadLocation() {
  navigator.geolocation.getCurrentPosition((position) => {
    const coords = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    }
    localStorage.setItem('coords', JSON.stringify(coords));
  })
}