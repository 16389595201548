import {Transition} from "@headlessui/react";
import xmark from "../assets/icons/system/xmark_red.svg";
import {Fragment, useState} from "react";
import {useNavigate} from "react-router-dom";
import ApiController from "../controllers/apiController";
import {useMutation, useQuery} from "@tanstack/react-query";
import UserController from "../controllers/UserController";
import cachedQueryClient from "../utils/cachedQueryClient";
import Spinner from "./Spinner";
import clsx from "clsx";

interface LocationDropdownProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

export default function LocationDropdown(props: LocationDropdownProps) {
  const navigate = useNavigate();
  const [location, setLocation] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const {isLoading, isError, data, error} = useQuery({
    queryKey: ['locations'], queryFn: UserController.getSavedLocations,
    gcTime: 1000 * 60,
    staleTime: 1000 * 60 * 5,
  }, cachedQueryClient)

  const removeLocationMutation = useMutation({
    mutationFn: ApiController.deleteLocation,
    onMutate: async (_: string) => {
      await cachedQueryClient.cancelQueries({queryKey: ['locations']});
      const previousData = cachedQueryClient.getQueryData(['locations']);
      return {previousData};
    },
    onError: (err, variables, context) => {
      console.error(err);
      if (context?.previousData)
        cachedQueryClient.setQueryData(['locations'], context.previousData);
    },
    onSettled: (data, error, variables) => {
      cachedQueryClient.setQueryData(['locations'], (old: any) => ({
        ...old,
        locations: old.locations.filter((l: string) => l !== variables)
      }));
      cachedQueryClient.invalidateQueries({queryKey: ['locations']}).then(r => console.log(r));
      cachedQueryClient.refetchQueries({queryKey: ['locations']}).then(r => console.log(r));
    }
  })

  const addLocationMutation = useMutation({
    mutationFn: ApiController.addLocation,
    onMutate: async (location: string) => {
      await cachedQueryClient.cancelQueries({queryKey: ['locations']});
      const previousData = cachedQueryClient.getQueryData(['locations']);
      cachedQueryClient.setQueryData(['locations'], (old: any) => ({
        ...old,
        locations: [...old.locations, location]
      }));
      return {previousData};
    },
    onError: (err, variables, context) => {
      console.error(err);
      if (context?.previousData)
        cachedQueryClient.setQueryData(['locations'], context.previousData);
    },
    onSettled: (data, error, variables) => {
      cachedQueryClient.invalidateQueries({queryKey: ['locations']}).then(r => console.log(r));
      cachedQueryClient.refetchQueries({queryKey: ['locations']}).then(r => console.log(r));
    }
  })

  const handleMoveToLocation = (location: string) => {
    navigate(`/city/${location}`);
    props.setShow(false);
  };

  if (isLoading) {
    return <Spinner/>
  }

  return (
    <Transition
      show={props.show}
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <div className="absolute -left-4 px-4 z-10 mt-5 flex w-screen max-w-max transition ease-out duration-200">
        <div
          className="w-screen max-w-md flex-auto overflow-hidden rounded-xl bg-gray-100 bg-opacity-10 backdrop-blur-2xl text-sm leading-6 shadow-lg">
          <div className="p-3">

            <div className={""}>
              <div className={"flex flex-col gap-2"}>
                <span className={"text-primary-50 font-bold mb-2"}>Add new location</span>
              </div>
              <form  onSubmit={(e) => {
                e.preventDefault();
                (e.target as HTMLFormElement).reset();
                addLocationMutation.mutate(location)
              }}>
                <input onChange={(event) => {
                  setLocation(event.target.value)
                }}
                       className={"w-full rounded-lg text-primary-50 bg-gray-100 bg-opacity-20 outline-none focus:ring-[3px] focus:ring-primary-200 px-3 py-2 transition-all duration-100 placeholder:text-primary-50 placeholder:text-opacity-50"}
                       placeholder={'Search new city ...'}/>
                {/*{error && <span className={"text-red-500"}>{error}</span>}*/}
                <button type={"submit"}
                        className={"mt-3 w-full rounded-lg bg-primary-500 text-primary-50 font-bold py-2 hover:bg-primary-600 transition-all duration-200 focus:ring-[3px] focus:ring-primary-200"}>
                  Add location
                </button>
              </form>
            </div>

            <hr className={"mt-3 opacity-30 rounded mb-3"}/>
            <div className={"flex flex-row items-center justify-between gap-2"}>
              <span className={"text-primary-50 font-bold"}>Saved locations</span>
              <button
                className={"text-primary-50 font-thin text-xs border-[1px] border-opacity-35 text-opacity-50 border-white rounded-xl px-[8px] hover:text-opacity-100 hover:border-opacity-100 transition-all duration-300"}
                onClick={() => setIsEditing(!isEditing)}
              >
                {isEditing ? "Done" : "Edit"}
              </button>
            </div>

            {data?.locations.map((locationItem, index) => {
              return (
                <div
                  key={index}
                  onClick={isEditing ? undefined : () => handleMoveToLocation(locationItem)}
                  className={clsx("flex flex-row items-center justify-between w-full gap-x-6 rounded-lg px-4 py-2 hover:bg-gray-100 hover:bg-opacity-10", {'cursor-pointer': !isEditing})}>
                  <span className="text-primary-50">
                    {locationItem}
                  </span>
                  {isEditing &&
                      <button
                          className="flex z-10 justify-center items-center rounded-full h-5 w-5 bg-gray-100 bg-opacity-10"
                          onClick={() => {
                            removeLocationMutation.mutate(locationItem)
                          }}>
                        {removeLocationMutation.isPending && removeLocationMutation.variables === locationItem ? (
                          <Spinner/>
                        ) : (
                          <img src={xmark} alt="delete" className="h-6 w-6"/>
                        )}
                      </button>
                  }
                </div>
              )
            })}

          </div>
        </div>
      </div>
    </Transition>
  )
}