import {Outlet, useNavigate} from "react-router-dom";
import Header from "../components/Header";
import "../css/scroll-bar.css";
import {Suspense, useEffect} from "react";
import ApiController from "../controllers/apiController";
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {QueryClient} from "@tanstack/react-query";
import {Persister, PersistQueryClientProvider} from '@tanstack/react-query-persist-client'
import {createSyncStoragePersister} from '@tanstack/query-sync-storage-persister'
import cachedQueryClient from "../utils/cachedQueryClient";

const queryClient = new QueryClient()

const persister: Persister = createSyncStoragePersister({
  storage: window.localStorage,
})

export default function LayoutRoot() {
  const navigate = useNavigate();

  useEffect(() => {
    ApiController.getUserInfos().then((user) => {
      if (!user) {
        localStorage.removeItem('token');
        navigate('/auth/login');
      }
    })
  }, [navigate]);

  return (
    <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-0 md:pt-20">
      <div className="mx-auto max-w-md rounded-2xl">
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{persister}}
          onSuccess={() => {
            cachedQueryClient.resumePausedMutations().then(() => {
              cachedQueryClient.invalidateQueries().then(r => console.log(r))
            })
          }}
        >
          <Header/>
          <Suspense fallback={<>Loading ...</>}>
            <Outlet/>
          </Suspense>
          <ReactQueryDevtools initialIsOpen={false}/>
        </PersistQueryClientProvider>
      </div>
    </main>
  )
}
