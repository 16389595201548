import {ReactNode} from "react";
import classNames from "classnames";

interface CardProps {
  img?: string;
  title?: string;
  className?: string;
  children: ReactNode;
}

export default function Card({img, title, children, className}: CardProps) {
  return (
    <div className={classNames("bg-gray-100 bg-opacity-10 backdrop-blur-md rounded-xl text-primary-100", className)}>
      {title && (
        <div
          className={"flex justify-start items-center px-3 py-2 border-b border-primary-100 border-opacity-25 text-xs uppercase text-white text-opacity-50 font-light"}>
          {img && <img src={img} alt="temperature icon" className={"w-4 h-4 opacity-50 me-2"}/>}
          {title}
        </div>
      )}
      <div className={"px-3 py-2"}>
        {children}
      </div>
    </div>
  )
}
