import ApiController from "../controllers/apiController";
import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";

export default function PageLogin() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const loadLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const coords = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      }
      localStorage.setItem('coords', JSON.stringify(coords));
    })
  }

  const login = () => {
    loadLocation();
    ApiController.login(email, password)
      .then((response) => {
        if (response === "") {
          setError("")
          navigate("/");
        } else {
          setError(response);
        }
      });
  }

  return (
    <div className={"pt-32"}>
      <div
        className="rounded-xl bg-gray-100 bg-opacity-10 backdrop-blur-2xl text-sm leading-6 text-primary-100 p-4 flex flex-col justify-center gap-4">
        <h1 className="text-center text-4xl font-bold">Sign in</h1>
        <div>
          <input type="email" id="email" placeholder={"Email address"}
                 className="w-full rounded-lg text-primary-50 bg-gray-100 bg-opacity-20 outline-none focus:ring-[3px] focus:ring-primary-200 px-3 py-2 transition-all duration-100 placeholder:text-primary-50 placeholder:text-opacity-50"
                 onChange={(event) => setEmail(event.target.value)}/>
        </div>
        <div>
          <input type="password" id="password" placeholder={"Password"}
                 className="w-full rounded-lg text-primary-50 bg-gray-100 bg-opacity-20 outline-none focus:ring-[3px] focus:ring-primary-200 px-3 py-2 transition-all duration-100 placeholder:text-primary-50 placeholder:text-opacity-50"
                 onChange={(event) => setPassword(event.target.value)}/>
        </div>
        <Link to="/auth/register" className="font-xs text-white font-thin hover:underline">Don't have an account ?
          Register now !</Link>
        <button
          className="w-full rounded-lg bg-primary-500 text-primary-50 font-bold py-2 hover:bg-primary-600 transition-all duration-200 focus:ring-[3px] focus:ring-primary-200"
          onClick={login}
        >Login
        </button>
        {error !== "" && (
          <p className="text-red-500 text-sm">{error}</p>
        )}
      </div>
    </div>
  )
}
