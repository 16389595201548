import {isRouteErrorResponse, useRouteError } from "react-router-dom";

const PageErrorBoundaryMessage: Map<number, string> = new Map([
  [404, "Sorry, we couldn't find the page you're looking for."],
])

export default function PageErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return (
      <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-primary-900">{error.status}</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-primary-600 sm:text-5xl">{error.statusText}</h1>
          <p className="mt-6 text-base leading-7 text-primary-600">{PageErrorBoundaryMessage.get(error.status) ?? 'Something went wrong !'}</p>
        </div>
      </main>
    )
  }

  throw error;
}