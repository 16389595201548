import ForecastItem from "../components/ForecastItem";
import {OpenWeatherForecast} from "../models/OpenWeather";

interface IProps {
  forecast: OpenWeatherForecast | undefined;
}

export default function Forecast(props: IProps) {
  return (
    <div className={"flex justify-start items-start overflow-x-scroll gap-3 py-2"}>
      {props.forecast?.list.map((item, index) => {
        return (
          <ForecastItem forecast={item} key={index}/>
        )
      })}
    </div>
  )
}
