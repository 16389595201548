import {useEffect, useState} from "react";
import {User} from "../models/User";
import Button from "./Button";
import LocationDropdown from "./LocationDropdown";
import account from "../assets/icons/system/circle_user.svg";
import list from "../assets/icons/system/list.svg";
import xmark from "../assets/icons/system/xmark.svg";
import location from "../assets/icons/system/location.svg";

export default function Header() {
  const [user, setUser] = useState<User | null>(null);
  const [showLocationList, setShowLocationList] = useState<boolean>(false);

  useEffect(() => {
    const sUser = localStorage.getItem("user");
    if (!sUser) {
      return;
    }
    const user = JSON.parse(sUser);
    setUser(user);
  }, []);

  const toggleLocationList = () => {
    setShowLocationList(!showLocationList);
  }

  return (
    <div className="flex flex-row gap-2 justify-between">
      <div className={"relative"}>
        <Button onClick={toggleLocationList}>
          {showLocationList ? (
            <>
              <img src={xmark} alt="refresh current data" className={"w-6 h-6"}/>
              <span className={"ms-1"}>Close</span>
            </>
          ) : (
            <>
              <img src={list} alt="refresh current data" className={"w-6 h-6"}/>
              <span className={"ms-1"}>My locations</span>
            </>
          )}
        </Button>
        <LocationDropdown show={showLocationList} setShow={setShowLocationList}/>
      </div>
      <div className={"flex flex-row gap-2"}>
        <Button icon={true} to={"/"}>
          <img src={location} alt="refresh current data" className={"w-6 h-6"}/>
        </Button>
        <Button to={'/me'}>
          <img src={account} alt="refresh current data" className={"w-6 h-6"}/>
          <span className={"ms-1"}>{user ? user.name : ""}</span>
        </Button>
      </div>
    </div>);
}
