import {User} from "../models/User";
import {Weather} from "../models/OpenWeather";

const API_URL = process.env.REACT_APP_OURWEATHER_API_URL;

class ApiController {

  // MARK: - New Definition

  static async fetchWeatherFromCoords(coords: { latitude: number, longitude: number }): Promise<{
    city: string,
    weather: Weather
  } | null> {
    return fetch(`${API_URL}/weather/from-coords?lat=${coords.latitude}&lon=${coords.longitude}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json().then(body => {
        return body;
      }))
  }

  static async fetchForecastFromCoords(coords: { latitude: number, longitude: number }): Promise<any | null> {
    return fetch(`${API_URL}/weather/forecast/from-coords?lat=${coords.latitude}&lon=${coords.longitude}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json().then(body => {
        return body;
      }))
  }

  static async fetchWeatherFromCity(city: string): Promise<{
    city: string,
    weather: Weather
  } | null> {
    return fetch(`${API_URL}/weather?city=${city}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json().then(body => {
        return body;
      }))
  }

  static async fetchForecastFromCity(city: string): Promise<any | null> {
    return fetch(`${API_URL}/weather/forecast?city=${city}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json().then(body => {
        return body;
      }))
  }

  // MARK: - Old API Code

  static async login(email: string, password: string): Promise<string> {
    const res = await fetch(`${API_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email: email, password: password})
    });
    const body = await res.json();
    if (res.status !== 200) {
      return body.message;
    }
    localStorage.setItem('token', body.token);
    localStorage.setItem('user', JSON.stringify(body.user));
    return "";
  }

  static async register(email: string, name: string, password: string): Promise<string> {
    return fetch(`${API_URL}/auth/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email, name, password})
    })
      .then(res => res.json().then(body => {
        if (res.status !== 200) {
          return body.message;
        }
        return "";
      }))
      .catch((err: Error) => {
        console.error(err);
      })
  }

  static async addLocation(city: string): Promise<boolean> {
    const res = await fetch(`${API_URL}/user/locations`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({name: city})
    });
    return res.status === 200;
  }

  static async listLocations(): Promise<string[]> {
    return fetch(`${API_URL}/user/locations`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then((res) => res.json())
      .catch((err: Error) => {
        console.error(err);
      })
  }

  static async deleteLocation(location: string): Promise<boolean> {
    const res = await fetch(`${API_URL}/user/locations`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
      method: 'DELETE',
      body: JSON.stringify({name: location})
    });
    return res.status === 200;
  }

  static async getUserInfos(): Promise<User | null> {
    const res = await fetch(`${API_URL}/user`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    if (res.status !== 200) {
      return null;
    }
    const body = await res.json();
    return await body.user;
  }

  static async postNotificationSubscription(subscription: any): Promise<boolean> {
    const res = await fetch(`${API_URL}/user/noftifications`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({subscription})
    });
    return res.status === 200;
  }

  static async sendTestNotification(): Promise<boolean> {
    const res = await fetch(`${API_URL}/user/noftifications`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
      method: 'GET',
    });
    return res.status === 200;
  }
}

export default ApiController;
